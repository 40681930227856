const initialState = { currentUser: null };

function loginReducer(state = initialState, action) {
  let nextState;
  switch (action.type) {
    case "LOGIN_USER":
      nextState = {
        ...state,
        currentUser: action.value,
      };
      return nextState || state;
    default:
      return state;
  }
}
export default loginReducer;
