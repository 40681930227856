import axios from "axios";
import api_url from "../../config";

const authAxios = axios.create({
  baseURL: api_url + "public",
});

class PublicService {
  getAllJobs(jobName, currentPage, limit) {
    let params = {
      jobName: jobName,
      page: currentPage,
      limit: limit,
      order: "desc",
      orderBy: "updatedAt"
    };
    return authAxios.get(`/jobs`, {
      params,
      withCredentials: true,
    });
  }

  getSingleJob(id) {
    return authAxios.get(`/jobs/${id}`, {
      withCredentials: true,
    });
  }

  getCompanyParams() {
    return authAxios.get(`/company-params`, {
      withCredentials: true,
    });
  }

  getAllCities() {
    let params = {
      
    };
    return authAxios.get(`/cities`, {
      params,
      withCredentials: true,
    });
  }

  getAllDepartments(departmentName, currentPage, limit){
    let params = {
      departmentName: departmentName,
      page: currentPage,
      limit: limit,
    };
    return authAxios.get(`/departments`, {
      params,
      withCredentials: true,
    });
  }
  postNewsLetterSubscription(data){
    return authAxios.post(`/newsletter/subscribe`, data,  {
      withCredentials: true,
    });
  }

  postApplication(data) {
    return authAxios.post(`/candidates/add`, data,  {
      withCredentials: true,
    });
  }
}
export default new PublicService();
