import React, { Component } from "react";
// import { Container, Row, Col } from "react-bootstrap";
import SobrusLogo from "../../../images/publicRecruitmentModule/logo_footer.png";
import supportEmail from "../../../images/publicRecruitmentModule/support_email_without_icon.png";
import "./Footer.css";
import { FaHeart } from "react-icons/fa";

export default class Footer extends Component {
    render() {
        return (
            <div className="public_recruitment">
                <hr />

                <div className="container-sm Footer">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
                        <div className="Copyright col">
                            <img src={SobrusLogo} alt="sobrus_logo" />
                            <br />
                            {/* <p>
                                Réalisé avec{" "}
                                <FaHeart style={{ color: "red", marginBottom: "5px" }} /> au Maroc
                            </p>
                            <br /> */}
                            <p>© 2021 Sobrus.</p>
                            <a
                                href="https://www.facebook.com/Sobrusgroup/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <div className="icon_facebook"></div>
                            </a>
                        </div>
                        <div className="col">
                            <h4 className="footer_h4">
                                Nos <strong>solutions</strong>
                            </h4>
                            <ul className="liens_footer">
                                <li>
                                    <a
                                        href="https://ma.pharma.sobrus.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Sobrus Pharma
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://labs.sobrus.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Sobrus Labs
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://sns.sobrus.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Sobrus SNS
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://med.sobrus.com/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Sobrus MED
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h4 className="footer_h4">
                                LIENS <strong>UTILES</strong>
                            </h4>
                            <ul className="liens_footer">
                                <li>
                                    <a
                                        href="https://monpharmacien.ma"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Annuaire de Pharmacies
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://monmedicament.ma"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Annuaire de Médicaments
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://chezledocteur.ma"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        Annuaire de Médecins
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col">
                            <h4 className="footer_h4">
                                CONTACTEZ-<strong>NOUS</strong>
                            </h4>
                            <ul className="liens_footer_contact">
                                <li>
                                    <div className="icon_tel"></div>
                                    <p>05 30 500 500</p>
                                </li>
                                <li>
                                    <div className="icon_msg"></div>
                                    <img
                                        className="email-img"
                                        src={supportEmail}
                                        alt="email"
                                        style={{ width: "68%", marginTop: "2px" }}
                                    />
                                    {/* <p>support@sobrus.com</p> */}
                                </li>
                                <li>
                                    <div className="icon_site"></div>
                                    <a href="https://sobrus.com" target="_blank" rel="noreferrer">
                                        Sobrus.com
                                    </a>
                                </li>
                                <li>
                                    <div className="icon_adresse"></div>
                                    <p>Rabat, Maroc</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
