import React, { Component } from "react";

// css
import "./ApplicationForm.css";

// select
import PublicService from "../../../services/publicRecruitmentModule/PublicService";
import ReCaptchaV2 from "react-google-recaptcha";
import RecruitmentCustomInput from "../../../helpers/publicRecruitmentModule/RecruitmentCustomInput";
import RecruitmentAsyncSelect from "../../../helpers/publicRecruitmentModule/RecruitmentAsyncSelect";
import styled from "styled-components";
import RecruitmentReactSelect from "../../../helpers/publicRecruitmentModule/RecruitmentReactSelect";
import { withRouter } from "react-router";
import ScaleLoader from "react-spinners/ScaleLoader";
import morroco from "../../../images/morocco.svg";
import france from "../../../images/france.png";
import { BiChevronDown } from "react-icons/bi";
class ApplicationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: [],
            city: "",
            jobs: [],
            jobName: "",
            currentPage: 1,
            elementsPerPage: 1000,
            totalItems: 0,
            is_loaded: false,
            allAvailabilities: [
                { value: "immediate", label: "Disponible" },
                { value: "in_a_month", label: "Dans un mois" },
                { value: "more_then_a_month", label: "Plus qu'un mois" },
            ],
            // ======= Form data ======= //
            firstName: "",
            lastName: "",
            cityId: null,
            cin: "",
            mobileNumber: "",
            email: "",
            availability: "",
            desiredPositionId: this.props.job ? this.props.job : null,
            resumeFile: "",

            requestLoading: false,

            errors: {},
            successNotif: false,
            errorNotif: false,
            phone: {
                image: morroco,
                indicatif: "+212",
            },
            open: false,
        };
        this._reCaptchaRef = React.createRef();
    }

    componentDidMount() {
        this.getAllCities();
        this.getAllJobs();
        document.body.style.width = "initial !important";
        // document..style.width = "initial !important";
        document.body.style.height = "initial !important";
    }

    componentWillUnmount() {
        document.body.style.width = "100% !important";
        document.body.style.height = "100% !important";
    }

    componentDidUpdate(prevProps) {
        if (prevProps.job !== this.props.job) {
            this.setState({ desiredPositionId: this.props.job });
        }
    }

    //   handleToken = (token) => {
    //     setForm((currentForm) => {
    //       return { ...currentForm, token };
    //     });
    //   };

    //   handleExpire = () => {
    //     setForm((currentForm) => {
    //       return { ...currentForm, token: null };
    //     });
    //   };

    getAllCities = async () => {
        try {
            let response = await PublicService.getAllCities();
            this.setState({
                is_loaded: true,
                cities: response.data.cities.map((city) => {
                    return { value: city.id, label: city.name };
                }),
            });
        } catch (error) {
            console.warn(error);
        }
    };
    getAllJobs = async () => {
        const { jobName, currentPage, elementsPerPage } = this.state;
        try {
            let response = await PublicService.getAllJobs(jobName, currentPage, elementsPerPage);
            this.setState({
                is_loaded: true,
                jobs: response.data.jobs.map((job) => {
                    return { value: job.id, label: job.jobName };
                }),
                totalItems: response.data.total,
            });
        } catch (error) {
            console.warn(error);
        }
    };

    addApplication = async (e) => {
        e.preventDefault();
        this.setState({ requestLoading: true }, async () => {
            if (this.handleValidation()) {
                let formData = new FormData();
                formData.append("firstName", this.state.firstName);
                formData.append("lastName", this.state.lastName);
                formData.append("cityId", this.state.cityId?.value);
                if (this.state.cin) {
                    formData.append("cin", this.state.cin);
                }
                formData.append("mobileNumber", this.state.phone.indicatif + this.state.mobileNumber);
                formData.append("email", this.state.email);
                formData.append("availability", this.state.availability?.value);
                if (this.props.location.pathname !== "/join-us") {
                    formData.append("desiredPositionId", this.state.desiredPositionId?.value);
                }
                formData.append("resumeFile", this.state.resumeFile);
                try {
                    await PublicService.postApplication(formData);
                    this.setState({
                        firstName: "",
                        lastName: "",
                        cityId: null,
                        cin: "",
                        mobileNumber: "",
                        email: "",
                        availability: "",
                        desiredPositionId: null,
                        resumeFile: "",
                        request: null,
                        requestLoading: false,
                    });
                    this.setState({ successNotif: true });
                    setTimeout(() => {
                        this.setState({
                            successNotif: false,
                        });
                    }, 4000);
                } catch (error) {
                    this.setState({ requestLoading: false });
                    console.warn(error);
                }
            } else {
                this.setState({
                    errorNotif: true,
                    notifMsg: "Veuillez Remplir les champs",
                    requestLoading: false,
                });
                setTimeout(() => {
                    this.setState({
                        errorNotif: false,
                        notifMsg: "",
                    });
                }, 4000);
            }
        });
    };

    handleValidation() {
        let errors = {};
        let formIsValid = true;

        //Name
        if (this.state.firstName === "") {
            formIsValid = false;
            errors["firstName"] = "Ce champ est obligatoire";
        }

        if (this.state.firstName !== "") {
            if (!this.state.firstName.match(/^[a-zA-Z\s!@#\$%\^\&*\)\(+=._-]+$/)) {
                formIsValid = false;
                errors["firstName"] = "Uniquement des lettres sont permis";
            }
        }

        if (this.state.lastName === "") {
            formIsValid = false;
            errors["lastName"] = "Ce champ est obligatoire";
        }

        if (this.state.lastName !== "") {
            if (!this.state.lastName.match(/^[a-zA-Z\s!@#\$%\^\&*\)\(+=._-]+$/)) {
                formIsValid = false;
                errors["lastName"] = "Uniquement des lettres sont permis";
            }
        }

        if (this.state.cityId === null) {
            formIsValid = false;
            errors["cityId"] = "Ce champ est obligatoire";
        }

        if (this.state.mobileNumber === "") {
            formIsValid = false;
            errors["mobileNumber"] = "Ce champ est obligatoire";
        } else {
            console.log(this.state.mobileNumber.length,"length")
            if (this.state.mobileNumber.length !== 9) {
                formIsValid = false;
                errors["mobileNumber"] = "Ce champ doit avoir que des chiffres ex: 650123456";
            }
        }

        if (this.state.resumeFile === "") {
            formIsValid = false;
            errors["resumeFile"] = "Ce champ est obligatoire";
        } else {
            if (
                this.state.resumeFile.type !== "application/pdf" &&
                this.state.resumeFile.type !== "image/jpeg" &&
                this.state.resumeFile.type !== "image/png"
            ) {
                formIsValid = false;
                errors["resumeFile"] = "Ce champ doit n'accepte que les fichiers pdf/jpeg/png";
            }
        }

        if (this.state.availability === "") {
            formIsValid = false;
            errors["availability"] = "Ce champ est obligatoire";
        }

        if (this.state.email === "") {
            formIsValid = false;
            errors["email"] = "Ce champ est obligatoire";
        }

        if (this.state.email !== "") {
            let lastAtPos = this.state.email.lastIndexOf("@");
            let lastDotPos = this.state.email.lastIndexOf(".");

            if (
                !(
                    lastAtPos < lastDotPos &&
                    lastAtPos > 0 &&
                    this.state.email.indexOf("@@") === -1 &&
                    lastDotPos > 2 &&
                    this.state.email.length - lastDotPos > 2
                )
            ) {
                formIsValid = false;
                errors["email"] = "Email n'est pas valide";
            }
        }

        if (!this.state.captchaChecked) {
            formIsValid = false;
            errors["captcha"] = "Cochez la case 'je ne suis pas un robot'";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    asyncScriptOnLoad = () => {
        console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
        // let frame = document.querySelector('iframe[title="Test reCAPTCHA"]');
        // frame.sandbox = frame.sandbox + " allow-scrolling";
        // console.log(frame);
    };

    render() {
        const { allAvailabilities, successNotif, errorNotif, cities } = this.state;
        return (
            <>
                {successNotif && (
                    <div className="notification" style={{ background: "#ECF9F8 0% 0% no-repeat padding-box" }}>
                        <div className="notification__success-icon" />
                        <div className="notification__msg">
                            <p>Votre candidature a été envoyée avec succès</p>
                        </div>
                        <div
                            className="notification__close"
                            onClick={() => {
                                this.setState({
                                    successNotif: false,
                                });
                            }}
                        ></div>
                    </div>
                )}
                {errorNotif && (
                    <div className="notification" style={{ background: "#f9ecec 0% 0% no-repeat padding-box" }}>
                        <div className="notification__error-icon" />
                        <div className="notification__msg">
                            <p>Veuillez remplir les champs</p>
                        </div>
                        <div
                            className="notification__close"
                            onClick={() => {
                                this.setState({
                                    errorNotif: false,
                                });
                            }}
                        ></div>
                    </div>
                )}
                <div className="public_recruitment">
                    <form
                        onSubmit={(e) => {
                            if (!this.state.requestLoading) {
                                this.addApplication(e);
                            }
                        }}
                    >
                        <h2>Postuler</h2>
                        {this.props.location.pathname !== "/join-us" && (
                            <div className="row pb-4">
                                <div className="col">
                                    <div className="input-container">
                                        <RecruitmentAsyncSelect
                                            fetchedUrl="/jobs"
                                            HTTPMethod="getjobs"
                                            receivedObject={"jobs"}
                                            optionLabelProperty={"jobName"}
                                            optionValueProperty="id"
                                            value={this.state.desiredPositionId}
                                            placeholder="Poste *"
                                            error={this.state.errors["desiredPositionId"]}
                                            onChange={(e) => {
                                                if (e) {
                                                    this.setState(
                                                        {
                                                            desiredPositionId: {
                                                                value: e.value,
                                                                label: e.label,
                                                            },
                                                        },
                                                        () => {
                                                            this.props.history.push(`/jobs/${e.value}/details`);
                                                        }
                                                    );
                                                }
                                            }}
                                        />
                                        {this.state.errors["desiredPositionId"] && (
                                            <ErrorContainer>
                                                <svg
                                                    aria-hidden="true"
                                                    width="16.035"
                                                    height="10.86"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="times-circle"
                                                    class="svg-inline--fa fa-times-circle fa-w-16"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path
                                                        fill="#F44336"
                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                    ></path>
                                                </svg>
                                                <ErrorText>
                                                    {this.state.errors["desiredPositionId"]
                                                        ? this.state.errors["desiredPositionId"]
                                                        : "Ce champs est obligatoire"}
                                                </ErrorText>
                                            </ErrorContainer>
                                        )}
                                    </div>
                                    {!this.state.errors["desiredPositionId"] && (
                                        <div id="PostHelp" className="form-text">
                                            Choisissez le poste sur la liste déroulante.
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col pb-4">
                                <div className="input-container">
                                    <RecruitmentCustomInput
                                        error={this.state.errors["lastName"]}
                                        errorMessage={this.state.errors["lastName"]}
                                        onChange={(e) => this.setState({ lastName: e.target.value })}
                                        value={this.state.lastName}
                                        id="lastName"
                                        label="Nom *"
                                    ></RecruitmentCustomInput>
                                </div>
                            </div>
                            <div className="col pb-4">
                                <div className="input-container">
                                    <RecruitmentCustomInput
                                        error={this.state.errors["firstName"]}
                                        errorMessage={this.state.errors["firstName"]}
                                        onChange={(e) => this.setState({ firstName: e.target.value })}
                                        value={this.state.firstName}
                                        id="firstName"
                                        label="Prénom *"
                                    ></RecruitmentCustomInput>
                                </div>
                            </div>
                        </div>
                        <div className="row row-cols-1 row-cols-sm-2">
                            <div className="col pb-4">
                                <div className="input-container">
                                    <RecruitmentCustomInput
                                        error={false}
                                        errorMessage={false}
                                        onChange={(e) => this.setState({ cin: e.target.value })}
                                        value={this.state.cin}
                                        id="cin"
                                        label="CIN *"
                                    ></RecruitmentCustomInput>
                                </div>
                            </div>
                            <div className="col pb-4">
                                <div className="input-container">
                                    <div className="input-container">
                                        <RecruitmentReactSelect
                                            value={this.state.cityId}
                                            options={cities}
                                            onChange={(e) =>
                                                this.setState({
                                                    cityId: { value: e.value, label: e.label },
                                                })
                                            }
                                            placeholder="Ville *"
                                            error={this.state.errors["cityId"]}
                                        />
                                        {/* <RecruitmentAsyncSelect
                                            fetchedUrl="/cities"
                                            HTTPMethod="getcities"
                                            receivedObject={"cities"}
                                            optionLabelProperty={"name"}
                                            optionValueProperty="id"
                                            value={this.state.cityId}
                                            placeholder="Ville *"
                                            error={this.state.errors["cityId"]}
                                            onChange={(e) => {
                                                if (e) {
                                                    this.setState({
                                                        cityId: {
                                                            value: e.value,
                                                            label: e.label,
                                                        },
                                                    });
                                                }
                                            }}
                                        /> */}
                                        {this.state.errors["cityId"] && (
                                            <ErrorContainer>
                                                <svg
                                                    aria-hidden="true"
                                                    width="16.035"
                                                    height="10.86"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="times-circle"
                                                    class="svg-inline--fa fa-times-circle fa-w-16"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                >
                                                    <path
                                                        fill="#F44336"
                                                        d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                    ></path>
                                                </svg>
                                                <ErrorText>
                                                    {this.state.errors["cityId"]
                                                        ? this.state.errors["cityId"]
                                                        : "Ce champs est obligatoire"}
                                                </ErrorText>
                                            </ErrorContainer>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                                <div className="input-container">
                                    <RecruitmentCustomInput
                                        error={this.state.errors["email"]}
                                        errorMessage={this.state.errors["email"]}
                                        onChange={(e) => this.setState({ email: e.target.value })}
                                        value={this.state.email}
                                        id="email"
                                        label="Email *"
                                    ></RecruitmentCustomInput>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                                <div className="input-container" style={{ position: "reltive" }}>
                                    <div className="indicatifs">
                                        <div
                                            onClick={() => this.setState({ open: !this.state.open })}
                                            className="indicatifcontainer"
                                        >
                                            <img src={this.state.phone.image} alt="" width={24} height={18} />
                                            <div>{this.state.phone.indicatif}</div>
                                            <BiChevronDown />
                                            {this.state.open && (
                                                <div className="indicatifs__down">
                                                    <div
                                                        className="indicatifs__downitem"
                                                        onClick={() =>
                                                            this.setState({
                                                                phone: {
                                                                    image: morroco,
                                                                    indicatif: "+212",
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <img src={morroco} alt="" width={24} height={18} />
                                                        <div>+212</div>
                                                    </div>
                                                    <div
                                                        className="indicatifs__downitem"
                                                        onClick={() =>
                                                            this.setState({
                                                                phone: {
                                                                    image: france,
                                                                    indicatif: "+33",
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <img src={france} alt="" width={24} height={18} />
                                                        <div>+33</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <RecruitmentCustomInput
                                        className="input-tel"
                                        error={this.state.errors["mobileNumber"]}
                                        errorMessage={this.state.errors["mobileNumber"]}
                                        onChange={(e) => this.setState({ mobileNumber: e.target.value })}
                                        value={this.state.mobileNumber}
                                        id="mobileNumber"
                                        label="Téléphone *"
                                        placeholder="650123456  "
                                    ></RecruitmentCustomInput>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                                <div className="input-container">
                                    <RecruitmentReactSelect
                                        value={this.state.availability}
                                        options={allAvailabilities}
                                        onChange={(e) =>
                                            this.setState({
                                                availability: { value: e.value, label: e.label },
                                            })
                                        }
                                        placeholder="Disponibilité *"
                                        error={this.state.errors["availability"]}
                                    />
                                    {this.state.errors["availability"] && (
                                        <ErrorContainer>
                                            <svg
                                                aria-hidden="true"
                                                width="16.035"
                                                height="10.86"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="times-circle"
                                                class="svg-inline--fa fa-times-circle fa-w-16"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    fill="#F44336"
                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                ></path>
                                            </svg>
                                            <ErrorText>
                                                {this.state.errors["availability"]
                                                    ? this.state.errors["availability"]
                                                    : "Ce champs est obligatoire"}
                                            </ErrorText>
                                        </ErrorContainer>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className=" form-group" style={{ padding: "10px", border: "none" }}>
                                    <label htmlFor="formFile" className="form-label" style={{ padding: "3px 0px" }}>
                                        Curriculum Vitae *
                                    </label>
                                    <div className="button-wrap">
                                        {/* <label className="new-button" htmlFor="upload">
                      Choisissez un fichier
                    </label> */}
                                        <input
                                            id="upload"
                                            required
                                            // className="form-control"
                                            type="file"
                                            onChange={(e) => {
                                                this.setState({ resumeFile: e.target.files[0] });
                                            }}
                                        />
                                    </div>
                                    {this.state.errors["resumeFile"] && (
                                        <ErrorContainer>
                                            <svg
                                                aria-hidden="true"
                                                width="16.035"
                                                height="10.86"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="times-circle"
                                                class="svg-inline--fa fa-times-circle fa-w-16"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                            >
                                                <path
                                                    fill="#F44336"
                                                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                                ></path>
                                            </svg>
                                            <ErrorText>
                                                {this.state.errors["resumeFile"]
                                                    ? this.state.errors["resumeFile"]
                                                    : "Ce champs est obligatoire"}
                                            </ErrorText>
                                        </ErrorContainer>
                                    )}

                                    {!this.state.errors["resumeFile"] && (
                                        <div id="PostHelp" className="form-text" style={{ marginTop: 5 }}>
                                            Les fichiers doivent peser moins de 2 Mo.
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                                <div style={{ position: "relative", width: "100%" }}>
                                    <ReCaptchaV2
                                        ref={this._reCaptchaRef}
                                        sitekey="6LfykmAbAAAAABhPGkVW3rl-D7dITxkapmKgA15a"
                                        className="recaptcha"
                                        asyncScriptOnLoad={this.asyncScriptOnLoad}
                                        onChange={(value) => {
                                            if (value) {
                                                this.setState({ captchaChecked: true });
                                            }
                                        }}
                                        //   onChange={this.handleToken}
                                        //   onExpire={this.handleExpire}
                                    />
                                </div>
                                {this.state.errors["captcha"] && (
                                    <ErrorContainer>
                                        <svg
                                            aria-hidden="true"
                                            width="16.035"
                                            height="10.86"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="times-circle"
                                            class="svg-inline--fa fa-times-circle fa-w-16"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 512 512"
                                        >
                                            <path
                                                fill="#F44336"
                                                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
                                            ></path>
                                        </svg>
                                        <ErrorText>
                                            {this.state.errors["captcha"]
                                                ? this.state.errors["captcha"]
                                                : "Ce champs est obligatoire"}
                                        </ErrorText>
                                    </ErrorContainer>
                                )}
                            </div>
                        </div>
                        <div className="row pb-4">
                            <div className="col">
                                <button type="submit" className="btn btn-primary sbmtForm">
                                    {!this.state.requestLoading ? (
                                        "Postuler"
                                    ) : (
                                        <ScaleLoader height={20} width={5} radius={2} margin={2} color={"#fff"} />
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(ApplicationForm);

const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    padding-top: 5px;
    justify-content: flex-end;
`;
const ErrorText = styled.div`
    display: inline-block;
    color: #f44336;
    font-size: 10px;
    vertical-align: top;
`;
