import Select from "react-select";
import React, { Component } from "react";

class RecruitmentReactSelect extends Component {
  constructor(props) {
    super(props);
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
  }

  noOptionsMessage() {
    return this.props.noOptoins;
  }

  render() {
    const {
      value,
      placeholder,
      onChange,
      isDisabled,
      isClearable,
      options,
      error
    } = this.props;

    const indicatorSeparatorStyle = {
      alignSelf: 'stretch',
      backgroundColor: "#8080808c",
      marginBottom: 10,
      marginTop: 10,
      width: 0.5,
      marginRight: 5
    };
    
    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={indicatorSeparatorStyle} {...innerProps} />;
    };

    return (
      <Select
        value={value}
        noOptionsMessage={this.noOptionsMessage}
        components={{ IndicatorSeparator }}
        width={"100%"}
        isDisabled={isDisabled}
        escapeClearsValue={true}
        isClearable={isClearable}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        menuShouldScrollIntoView={true}
        cacheOptions
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          loadingIndicator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          container: (provided, state) => ({
            ...provided,
            width: "100%",
            height: "100%",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: "#808080",
            // fontWeight: "600",
            fontSize: "12px",
            fontFamily: "Open Sans, sans-serif",
          }),
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            height: "100%",
            fontFamily: "Open Sans, sans-serif",
            color: "#808080",
            fontSize: "12px",
            paddingLeft: "2px",
            appearance: "none",
            backgroundColor: "#fff",
            border: error ? "1px solid #F44336" : value ? "1px solid #19B0D2" : "1px solid #8080808c",
            ":hover": {
              border: "1px solid #19B0D2",
            },
            ":focus": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
            },
            ":focus-within": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
              boxShadow: "0 0 0 0 transparent",
            },
            ":active": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
            },
          }),
          input: (provided, state) => ({
            ...provided,
            fontFamily: "Open Sans, sans-serif",
            color: "#808080",
            fontSize: "12px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && "#19B0D2",
            color: state.isSelected && "#fff",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
            paddingTop: "10px",
            paddingBottom: "10px",
            ":hover": {
              color: "#fff",
              backgroundColor: "#19B0D2",
            },
          }),
          clearIndicator: (provided, state) => ({
            ...provided,
            paddingRight: 0,
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
          }),
        }}
      />
    );
  }
}

export default RecruitmentReactSelect;
