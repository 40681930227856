import React, { Component } from "react";
import "./Loader.css";
import ScaleLoader from "react-spinners/ScaleLoader";

export default class Loader extends Component {
  render() {
    return (
      <div className="LoadingContainer">
        <ScaleLoader
          height={50}
          width={10}
          radius={4}
          margin={4}
          color={"#19B0D2"}
        />
      </div>
    );
  }
}

