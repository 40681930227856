import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import mixpanel from 'mixpanel-browser';
// import { ProtectedRoute } from "./protected-admin-route";
import { ProtectedLoginRoute } from "./protected-login-route";
// import { ProtectedUserRoute } from './protected-user-route';

// import "antd/dist/antd.css";

// import "react-datepicker/dist/react-datepicker.css";
// import "./css/all_38.css";
import styled from "styled-components";
import { Provider } from "react-redux";
import Store from "./store/configureStore";
import "@fortawesome/fontawesome-free/css/all.min.css";
import JobDetail from "./pages/publicRecruitmentModule/JobDetail/JobDetail";
import OffersPage from "./pages/publicRecruitmentModule/OffersPage/OffersPage";
// import LandingPage from "./landing_page/LandingPage";

const LandingPageStyle = React.lazy(() => import("./LandingPageStyle"));
const PrivateAppStyle = React.lazy(() => import("./PrivateAppStyle"));

const ThemeSelector = ({ children }) => {
    const landingPageOpened = window.location.pathname === "/";
    return (
        <>
            <React.Suspense fallback={<></>}>
                {landingPageOpened && <LandingPageStyle />}
                {!landingPageOpened && <PrivateAppStyle />}
            </React.Suspense>
            {children}
        </>
    );
};

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_loaded: true,
        };
    }
    componentDidMount() {

        if (process.env.REACT_APP_ENV === "production") {
            const beusable = document.createElement('script');
            const hotjar = document.createElement('script');
            beusable.setAttribute('type', "text/javascript");
            beusable.appendChild(document.createTextNode(`(function(w, d, a){
            w.__beusablerumclient__ = {
                load : function(src){
                    var b = d.createElement("script");
                    b.src = src; b.async=true; b.type = "text/javascript";
                    d.getElementsByTagName("head")[0].appendChild(b);
                }
            };w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
        })(window, document, "//rum.beusable.net/load/b220902e185435u483");`));
            hotjar.appendChild(document.createTextNode(`!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
        posthog.init('phc_6ib4tIGMCaNgJDSGt9HiWrBnSPzQeK8VZF26N5u3Bkt',{api_host:'https://app.posthog.com'})`));
            document.head.appendChild(beusable);
            document.head.appendChild(hotjar);
            mixpanel.init('e8022dc7aefa6baada7e48d013166cd6', { debug: true });
            mixpanel.track('All app');
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(
            () =>
                this.setState({ is_loaded: false }, () =>
                    console.log("nouvelle version: 09 septembre 2022 13:30")
                ),
            500
        );
    }
    render() {
        return (
            <ThemeSelector>
                <Provider store={Store}>
                    <GlobalContainer>
                        <BrowserRouter>
                            <div style={{ flex: 1 }}>
                                <Switch>
                                    <Route path="/" exact render={() => <Redirect to="/jobs" />} />
                                    <Route path="/jobs" exact component={OffersPage} />
                                    <Route path="/jobs/:id/details" exact component={JobDetail} />
                                    <Route path="/join-us" exact component={JobDetail} />
                                </Switch>
                            </div>
                        </BrowserRouter>
                    </GlobalContainer>
                </Provider>
            </ThemeSelector>
        );
    }
}

// const Container = styled.div`
//   position: relative;
//   height: 100%;
//   background: #fff;
// `;
const GlobalContainer = styled.div`
    height: 100%;
`;

export default App;
