import React, { Component } from "react";
// import { Navbar, Container, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import SobrusLogo from "../../../images/publicRecruitmentModule/logo.png";
import "../../../css/bootstrap.css";
import "./Header.css";
import { withRouter } from "react-router-dom";

class Header extends Component {
    render() {
        const isMobile = window.matchMedia("(max-width: 850px)").matches;

        return (
            <div className="public_recruitment">
                <div>
                    <nav className="my-nav navbar navbar-expand navbar-light fixed-top">
                        <div className="nav--container container-sm">
                            <div className="navbar-brand">
                                <img
                                    src={SobrusLogo}
                                    height="37px"
                                    className="d-inline-block align-top brand-logo brandImg"
                                    alt="Sobrus logo"
                                    onClick={() => {
                                        this.props.history.push("/jobs");
                                    }}
                                />
                            </div>
                            <div className="navbar-collapse  nav-item--links">
                                <NavLink exact to="/jobs" activeClassName="TextActive">
                                    <p className="header__items">Liste des offres</p>
                                </NavLink>
                                {!isMobile && (
                                    <NavLink exact to="/join-us">
                                        <button
                                            type="button"
                                            className="cand-spont-btn btn btn-primary"
                                        >
                                            Candidature spontanée
                                        </button>
                                    </NavLink>
                                )}
                                {isMobile && (
                                    <NavLink exact to="/join-us" activeClassName="TextActive">
                                        <p className="header__items">Candidature spontanée</p>
                                    </NavLink>
                                )}
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
export default withRouter(Header);
