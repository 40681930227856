import React, { Component } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

class RecruitmentCustomInput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {
    // var textarea = document.querySelector("textarea");
    // textarea && (textarea);
    // textarea && textarea.addEventListener("keydown", autosize);
    // function autosize() {
    //     var el = this;
    //     setTimeout(function () {
    //         el.style.cssText = "height:auto; padding:0";
    //         // for box-sizing other than "content-box" use:
    //         // el.style.cssText = '-moz-box-sizing:content-box';
    //         el.style.cssText = "height:" + el.scrollHeight + "px";
    //     }, 0);
    // }
  }
  render() {
    const { error, type, errorMessage, editable, searchIcon, className} = this.props;

    return (
      <Container>
        {!(type === "textarea") && (
          <InputContainer>
            <Input
              editable={editable === false ? editable : true}
              onKeyDown={this.props.onKeyDown}
              borderColor={error ? "#F44336" : "#8080808c"}
              type={this.props.type}
              name="input"
              autoFocus={this.props.autoFocus}
              className={["input-login",className]}
              onChange={this.props.onChange}
              id={this.props.id}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue && this.props.defaultValue}
              value={this.props.value && this.props.value}
              contentEditable={false}
              required
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              step={this.props.step}
            />
            <Label
              color={error ? "#F44336" : "#808080"}
              id="input-label"
              htmlFor={this.props.id}
              className="label-login"
            >
              {this.props.label}
            </Label>
          </InputContainer>
        )}
        {type === "textarea" && (
          <InputContainer>
            <TextArea
              ref={(ref) => (this.textArea = ref)}
              onKeyDown={this.props.onKeyDown}
              borderColor={error ? "#F44336" : "#8080808c"}
              type={this.props.type}
              name="input"
              autoFocus={this.props.autoFocus}
              className={"input-login"}
              onChange={this.props.onChange}
              id={this.props.id}
              placeholder={this.props.placeholder}
              defaultValue={this.props.defaultValue && this.props.defaultValue}
              value={this.props.value && this.props.value}
              required
            />
            <TextAreaLabel
              color={error ? "#F44336" : "#808080"}
              id="input-label"
              htmlFor={this.props.id}
              className="label-login"
            >
              {this.props.label}
            </TextAreaLabel>
          </InputContainer>
        )}

        {error && (
          <ErrorContainer>
            <svg
              aria-hidden="true"
              width="16.035"
              height="10.86"
              focusable="false"
              data-prefix="fas"
              data-icon="times-circle"
              className="svg-inline--fa fa-times-circle fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#F44336"
                d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
              ></path>
            </svg>
            <ErrorText>
              {errorMessage ? errorMessage : `Ce champ est obligatoire`}
            </ErrorText>
          </ErrorContainer>
        )}
        {searchIcon && (
          <SearchIcon>
            <FaSearch />
          </SearchIcon>
        )}
      </Container>
    );
  }
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const InputContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Label = styled.label`
  position: absolute;
  left: 15px;
  top: 34%;
  ${
    "" /* font-family: Roboto;
    font-size: 14px; */
  }
  font-family: 'Open Sans', sans-serif;
  color: #494949;
  font-weight: 600;
  font-size: 12px;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
  color: ${(props) => props.color};
  font: "Open Sans", sans-serif;
  cursor: text;
`;
const TextAreaLabel = styled.label`
  position: absolute;
  background-color: #fff;
  top: -5px;
  width: auto;
  left: 7px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 11px;
  color: #494949;
  transition: all 0.1s ease;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  line-height: 0.94;
  letter-spacing: 0.22px;
  text-align: left;
`;
const Input = styled.input`
  font: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  resize: none;
  margin: 0;
  outline: none;

  padding-right: 20px;

  ${"" /* font-size: 14px; */}
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

  color: #494949;
  font-size: 12px;
  padding-left: 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: ${(props) => (props.editable ? "#ffffff" : "#f0f0f0")};
  border: solid 1px ${(props) => props.borderColor};
  border-radius: 6px;
  &:hover {
    border: solid 1px ${(props) => (props.editable ? "#19B0D2" : "#f0f0f0")};
  }
  &:focus-within,
  &:valid {
    border: solid 1px ${(props) => (props.editable ? "#19B0D2" : "#f0f0f0")};
  }
  &:focus ~ ${Label}, &:valid ~ ${Label} {
    background-color: #fff;
    top: -10%;
    width: auto;
    left: 7px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 11px;
    color: #19b0d2;
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &:not(focus) ~ ${Label}, &:not(valid) ~ ${Label} {
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
`;

const TextArea = styled.textarea`
  font: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 0;
  outline: none;

  padding-right: 20px;
  ${"" /* font-size: 14px; */}
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

  color: #494949;
  font-size: 12px;
  padding-left: 10px;
  padding-top: 10px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: #ffffff;
  border: solid 1px ${(props) => props.borderColor};
  border-radius: 6px;
  &:hover {
    border: solid 1px #19b0d2;
  }
  &:focus-within,
  &:valid {
    border: solid 1px #19b0d2;
  }
  &:focus ~ ${TextAreaLabel}, &:valid ~ ${TextAreaLabel} {
    background-color: #fff;
    color: #19b0d2;
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  &:not(focus) ~ ${TextAreaLabel}, &:not(valid) ~ ${TextAreaLabel} {
    transition: all 0.1s ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 5px;
  justify-content: flex-end;
`;
const ErrorText = styled.div`
  display: inline-block;
  color: #f44336;
  font-size: 10px;
  vertical-align: top;
`;
export const SearchIcon = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  top: 10px;
  right: 10px;
`;
export default RecruitmentCustomInput;
