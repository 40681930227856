import React, { Component } from "react";
import policyImg from "../../../images/publicRecruitmentModule/policy.svg";
import outline from "../../../images/publicRecruitmentModule/outline.svg";
import map from "../../../images/publicRecruitmentModule/map.svg";
import "./JobDetail.css";
import { Link } from "react-router-dom";
import PublicService from "../../../services/publicRecruitmentModule/PublicService";
import renderMarkup from "react-render-markup";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import ApplicationForm from "../../../components/publicRecruitmentModule/ApplicationForm/ApplicationForm";
// import { Container } from "react-bootstrap";
import Header from "../../../components/publicRecruitmentModule/Header/Header";
import NewsLetter from "../../../components/publicRecruitmentModule/NewsLetter/NewsLetter";
import Footer from "../../../components/publicRecruitmentModule/Footer/Footer";
import Loader from "../../../helpers/publicRecruitmentModule/Loader/Loader";
import { AiOutlineSearch, AiOutlineWarning } from "react-icons/ai";

export default class JobDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            is_loaded: true,
            address: "",
            aboutUs: "",
            id: this.props.match.params.id,
            application: false,
            jobNotFound: false,
            jobName: "",
        };
    }

    componentDidMount() {
        console.log(this.props.location);
        if (this.props.location.pathname === "/join-us") {
            this.setState({ application: true, is_loaded: false }, () => {
                this.getCompanyInfo();
            });
        } else {
            this.setState({ application: false, is_loaded: true, id: this.props.match.params.id }, () => {
                this.getSingleJob();
                this.getCompanyInfo();
            });
        }
        document.querySelector("html").style.zoom = 1;
        window.scrollTo({ top: 0, behavior: "smooth" });
        
    }
    componentWillMount() {
        this.unlisten = this.props.history.listen(() => {
            window.setTimeout(() => {
                if (this.props.location.pathname === "/join-us") {
                    this.setState({ application: true, is_loaded: true }, () => {
                        this.getCompanyInfo();
                    });
                } else {
                    this.setState(
                        {
                            application: false,
                            is_loaded: true,
                            id: this.props.match.params.id,
                        },
                        () => {
                            this.getSingleJob();
                            this.getCompanyInfo();
                        }
                    );
                }
            }, 0);
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    componentWillUnmount() {
        this.unlisten();
        document.querySelector("html").style.zoom = 0.9;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    getSingleJob = async () => {
        try {
            let response = await PublicService.getSingleJob(this.state.id);
            this.setState({
                is_loaded: false,
                data: response.data,
            });
        } catch (error) {
            console.warn(error);
            if (error.response.data.message === "job was not found !") {
                this.setState({ jobNotFound: true });
            }
        }
    };

    getCompanyInfo = async () => {
        try {
            let response = await PublicService.getCompanyParams();
            this.setState({
                is_loaded: false,
                address: response.data.address,
                aboutUs: response.data.aboutUs,
            });
        } catch (error) {
            console.warn(error);
        }
    };

    convertToHtml = (content) => {
        let newContent = convertFromRaw(JSON.parse(content));
        let options = {
            blockStyleFn: (block) => {
                if (block.getType() === "align-center") {
                    return {
                        style: {
                            "text-align": "center",
                        },
                    };
                }
                if (block.getType() === "align-left") {
                    return {
                        style: {
                            "text-align": "left",
                        },
                    };
                }
                if (block.getType() === "align-right") {
                    return {
                        style: {
                            "text-align": "right",
                        },
                    };
                }
                if (block.getType() === "justify") {
                    return {
                        style: {
                            "text-align": "justify",
                        },
                    };
                }
            },
        };
        let markup = stateToHTML(newContent, options);
        markup = markup.replaceAll("\n", "");
        return (
            <div className="job_section">
                <br></br> <div style={{ whiteSpace: "pre-wrap" }}>{renderMarkup(markup)}</div>
            </div>
        );
    };

    translateRemoteWorkPossibility = (param) => {
        switch (param) {
            case "Possible":
                return "Possible";
            case "Conditional":
                return "Sous condition";
            case "Partially":
                return "Partiellement possible";
            case "No":
                return "Non";
            default:
                return "--";
        }
    };

    render() {
        const { is_loaded, data, aboutUs, address, id, application } = this.state;
        return (
            <>
                <>
                    <Header />
                    {is_loaded ? (
                        <Loader />
                    ) : (
                        <div className="public_recruitment" style={{ color: "#fff" }}>
                            {this.state.jobNotFound && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "150px",
                                    }}
                                >
                                    <span style={{ fontSize: "100px", color: "#0E2038" }}>404</span>
                                    <div style={{ width: "410px", marginLeft: "50px" }}>
                                        <span
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <AiOutlineWarning color="#1CB1D3" size="28" />
                                            <span
                                                style={{
                                                    fontWeight: "400",
                                                    fontSize: "23px",
                                                    color: "#000",
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                Oops! Offre non trouvée.
                                            </span>
                                        </span>
                                        <p style={{ fontSize: "14px" }}>
                                            Nous n'avons pas pu trouver la page que vous recherchiez. En attendant, vous
                                            pouvez revenir à la liste des offres ou essayer d'utiliser le formulaire de
                                            recherche.
                                        </p>
                                        <div className="search" style={{ textAlign: "left" }}>
                                            <input
                                                type="search"
                                                onKeyPress={(event) => {
                                                    if (event.key === "Enter") {
                                                        this.props.history.push({
                                                            pathname: "/jobs",
                                                            state: { jobName: this.state.jobName },
                                                        });
                                                    }
                                                }}
                                                placeholder="Chercher une offre ici…"
                                                value={this.state.jobName}
                                                onChange={(e) => {
                                                    this.setState({
                                                        jobName: e.target.value,
                                                    });
                                                }}
                                            />
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    this.props.history.push({
                                                        pathname: "/jobs",
                                                        state: { jobName: this.state.jobName },
                                                    });
                                                    // this.getAllJobs();
                                                }}
                                            >
                                                <AiOutlineSearch
                                                    className="searchBtnIcon"
                                                    style={{
                                                        color: "#9D9D9D",
                                                        height: "30px",
                                                        width: "30px",
                                                    }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!this.state.jobNotFound && (
                                <div className="SOheader">
                                    <div className="container-sm">
                                        {!application ? (
                                            <div className="linkText">
                                                <Link to="/jobs">Accueil </Link> / <strong>{data?.jobName}</strong>
                                            </div>
                                        ) : (
                                            <div className="linkText">
                                                <Link to="/jobs">Accueil </Link> /{" "}
                                                <strong>Candidature spontanée</strong>
                                            </div>
                                        )}
                                        <h2 style={{ marginBottom: 10 }}>
                                            {!application ? data.jobName : "Candidature spontanée"}
                                        </h2>
                                        <div className="Headerinfo">
                                            <div className="firstRowInfo" style={{ marginBottom: 10 }}>
                                                <div className="cntratType">
                                                    <div className="cntratTypeImg">
                                                        <img className="svgImg" src={policyImg} alt="policyImg" />
                                                    </div>
                                                    <div className="cntratTypeTxt">
                                                        <b>Type d’emploi</b>
                                                        <p>
                                                            {!application && data?.contractType
                                                                ? data?.contractType === "Internship"
                                                                    ? "Stage PFE"
                                                                    : data?.contractType
                                                                : "--"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="dist">
                                                <div className="cntratTypeImg">
                                                    <img
                                                        className="svgImg"
                                                        src={outline}
                                                        alt="outline"
                                                    />
                                                </div>
                                                <div className="distTxt">
                                                    <b>A distance</b>
                                                    <p>
                                                        {!application && data?.remoteWorkPossibility
                                                            ? this.translateRemoteWorkPossibility(
                                                                  data?.remoteWorkPossibility
                                                              )
                                                            : "--"}
                                                    </p>
                                                </div>
                                            </div> */}
                                            <div className="locRow">
                                                <div className="cntratTypeImg">
                                                    <img className="svgImg" src={map} alt="map" />
                                                </div>
                                                <div className="locTxt">
                                                    <b>Lieu</b>
                                                    <p>{address && address}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="SObody">
                                            <div className="SOleftBody">
                                                <h3>À propos de Sobrus</h3>
                                                {aboutUs && this.convertToHtml(aboutUs)}
                                                {!application && (
                                                    <div>
                                                        {data && data.description && (
                                                            <div>
                                                                <h3>À propos du poste</h3>
                                                                {this.convertToHtml(data.description)}
                                                            </div>
                                                        )}

                                                        {data && data.requiredProfile && (
                                                            <div>
                                                                <h3>Profil recherché :</h3>
                                                                {this.convertToHtml(data.requiredProfile)}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="SOrightBody">
                                                {this.props.location.pathname !== "/join-us" &&
                                                    data &&
                                                    data.jobName && (
                                                        <ApplicationForm
                                                            job={{
                                                                value: id,
                                                                label: data?.jobName,
                                                            }}
                                                        />
                                                    )}
                                                {this.props.location.pathname === "/join-us" && <ApplicationForm />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <NewsLetter />
                            <Footer />
                        </div>
                    )}
                </>
            </>
        );
    }
}
