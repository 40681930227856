import React, { Component } from "react";

import "./NewsLetter.css";
import "../../../css/bootstrap.css";
import PublicService from "../../../services/publicRecruitmentModule/PublicService";

export default class NewsLetter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            departments: [],
            departmentName: "",
            departmentId: "",
            email: "",
            currentPage: 1,
            elementsPerPage: 1000,
            totalItems: 0,
            is_loaded: false,
            successNotif: false,
            errorNotif: false,
            notifMsg: "",
        };
    }

    componentDidMount() {
        this.setState(
            {
                is_loaded: false,
            },
            () => this.getDepartments()
        );
    }

    getDepartments = async () => {
        try {
            const { departmentName, currentPage, elementsPerPage } = this.state;
            let response = await PublicService.getAllDepartments(
                departmentName,
                currentPage,
                elementsPerPage
            );
            this.setState({
                is_loaded: true,
                departments: response.data.departments,
                totalItems: response.data.totalItems,
            });
        } catch (error) {
            console.warn(error);
        }
    };

    submitNewsLetter = async () => {
        if (this.state.email !== "" && this.state.departmentId !== "") {
            try {
                const data = {
                    email: this.state.email,
                    departmentId: this.state.departmentId,
                };
                await PublicService.postNewsLetterSubscription(data);
                this.setState(
                    {
                        successNotif: true,
                        departmentName: "",
                        departmentId: "",
                        email: "",
                    },
                    () => {
                        setTimeout(() => {
                            this.setState({ successNotif: false });
                        }, 4000);
                    }
                );
            } catch (error) {
                if (
                    error.response.data.message ===
                    "email already subscribed to the provided department"
                ) {
                    this.setState({
                        errorNotif: true,
                        notifMsg: "Cette adresse e-mail est déjà enregistrée",
                    });
                    setTimeout(() => {
                        this.setState({
                            errorNotif: false,
                            notifMsg: "",
                        });
                    }, 4000);
                }
            }
        } else {
            this.setState({
                errorNotif: true,
                notifMsg: "Veuillez Remplir les champs",
            });
            setTimeout(() => {
                this.setState({
                    errorNotif: false,
                    notifMsg: "",
                });
            }, 4000);
        }
    };

    render() {
        const { departments, successNotif, errorNotif, email, departmentId, notifMsg } = this.state;
        return (
            <div className="public_recruitment">
                <div className="newsletter">
                    {successNotif && (
                        <div
                            className="notification"
                            style={{ background: "#ECF9F8 0% 0% no-repeat padding-box" }}
                        >
                            <div className="notification__success-icon" />
                            <div className="notification__msg">
                                <p>Votre e-mail a été enregistré avec succès</p>
                            </div>
                            <div
                                className="notification__close"
                                onClick={() => {
                                    this.setState({
                                        successNotif: false,
                                    });
                                }}
                            ></div>
                        </div>
                    )}
                    {errorNotif && (
                        <div
                            className="notification"
                            style={{ background: "#f9ecec 0% 0% no-repeat padding-box" }}
                        >
                            <div className="notification__error-icon" />
                            <div className="notification__msg">
                                <p>{notifMsg}</p>
                            </div>
                            <div
                                className="notification__close"
                                onClick={() => {
                                    this.setState({
                                        errorNotif: false,
                                        notifMsg: "",
                                    });
                                }}
                            ></div>
                        </div>
                    )}
                    <div className="container-sm">
                        <div className="newsletter__title">
                            <h2>Inscrivez-vous à notre newsletter</h2>
                        </div>
                        <div className="newsletter__description">
                            <p>Soyez le premier à être informé de nos dernières offres.</p>
                        </div>
                        <div className="newsletter__inputs">
                            <div className="newsletter__inputs-form">
                                <label htmlFor="exampleInputEmail1" className="form-label">
                                    Email address
                                </label>
                                <input
                                    required
                                    type="email"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    value={email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    placeholder="exemple@xyz.com"
                                    aria-describedby="emailHelp"
                                />
                            </div>
                            <div className="newsletter__inputs-form">
                                <select
                                    name="dep_select"
                                    id="dep_select"
                                    className="form-control"
                                    value={departmentId}
                                    onChange={(e) =>
                                        this.setState({ departmentId: e.target.value })
                                    }
                                >
                                    <option value="">Département</option>
                                    {departments &&
                                        departments.map((department, index) => {
                                            return (
                                                <option
                                                    value={department.id}
                                                    className="selectBoxOptions"
                                                    key={index}
                                                >
                                                    {department.departmentName}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                            <button
                                type="button"
                                onClick={() => this.submitNewsLetter()}
                                className="btn btn-primary"
                                style={{ fontSize: 14 }}
                            >
                                S'inscrire
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
