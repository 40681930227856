import React, { Component } from "react";
import Header from "../../../components/publicRecruitmentModule/Header/Header";
import Footer from "../../../components/publicRecruitmentModule/Footer/Footer";
import NewsLetter from "../../../components/publicRecruitmentModule/NewsLetter/NewsLetter";
import "./Jobs.css";
import SobeusO from "../../../images/publicRecruitmentModule/sobrusO.png";
import quote from "../../../images/publicRecruitmentModule/quote.png";
import OffersList from "../../../components/publicRecruitmentModule/Job/OffersList/OffersList";

export default class OffersPage extends Component {
    componentDidMount() {
        document.querySelector("html").style.zoom = 1;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    componentWillUnmount() {
        document.querySelector("html").style.zoom = 0.9;
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <div>
                <Header />
                <div className="public_recruitment" style={{ backgroundColor: "#fff" }}>
                    <div className="OfrLstHeader">
                        <div className="contentHeaderOfrLst">
                            <div>
                                <img src={SobeusO} className="sobrusO" alt="sobrus_logo" />
                                <div className="ofrLstTxt">
                                    <p>
                                        Rejoignez la startup{" "}
                                        <span className="fw-bold">
                                            qui pense chaque jour au bien-être de ses collaborateurs
                                        </span>
                                        <img src={quote} className="quote" alt="quote" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-sm">
                        <OffersList />
                    </div>
                    <NewsLetter />
                    <Footer />
                </div>
            </div>
        );
    }
}

// const Container = styled.div`
//   width: 60%;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 auto;
//   align-items: center;
// `;
