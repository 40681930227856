import AsyncSelect from "react-select/async";
import React, { Component } from "react";
import axios from "axios";
import api_url from "../../config";

const axiosInstance = axios.create({
  baseURL: api_url + "public",
});

const convertDataForSelectBox = (data, valueProperty, labelProperty) => {
  let convertedData = [];
  // convertedData.push({ value: "", label: "_Aucun_" });

  data.forEach((object) => {
    convertedData.push({
      value: valueProperty ? object[valueProperty] : object,
      label: labelProperty ? object[labelProperty] : "",
    });
  });
  return convertedData;
};

class RecruitmentAsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      defaultData: [],
      fetchedUrl: this.props.fetchedUrl,
    };
    this.noOptionsMessage = this.noOptionsMessage.bind(this);
  }

  componentDidMount() {
    this.getDefaultData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fetchedUrl !== this.props.fetchedUrl) {
      this.setState({ fetchedUrl: this.props.fetchedUrl }, () =>
        this.getData()
      );
    }
  }

  getData = async (inputValue = null, callback = () => {}) => {
    try {
      const {
        HTTPMethod,
        receivedObject,
        optionLabelProperty,
        optionValueProperty,
        onInputChange,
      } = this.props;
      const { fetchedUrl } = this.state;
      // for URLS that use POST HTTP method

      if (HTTPMethod.toLocaleLowerCase() === "post") {
        let data = {};
        if (inputValue) {
          data[optionLabelProperty] = {
            value: inputValue,
            operator: "contains",
          };

          onInputChange && onInputChange(inputValue);
        }
        const response = await axiosInstance.post(`${fetchedUrl}`, data, {
          withCredentials: true,
        });
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
        // for URLS that use GET HTTP method
      } else if (HTTPMethod.toLocaleLowerCase() === "getjobs") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await axiosInstance.get(
          `${fetchedUrl}?jobName=${content}`,
          {
            withCredentials: true,
          }
        );
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      } else if (HTTPMethod.toLocaleLowerCase() === "getcities") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await axiosInstance.get(
          `${fetchedUrl}?name=${content}`,
          {
            withCredentials: true,
          }
        );
        this.setState({
          data: receivedObject ? response.data[receivedObject] : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      }
    } catch (error) {
      this.setState({ is_loaded: false });
      console.log(error);
    }
  };

  getDefaultData = async (inputValue = null, callback = () => {}) => {
    try {
      const {
        HTTPMethod,
        receivedObject,
        optionLabelProperty,
        optionValueProperty,
        onInputChange,
      } = this.props;
      const { fetchedUrl } = this.state;
      // for URLS that use POST HTTP method

      if (HTTPMethod.toLocaleLowerCase() === "post") {
        let data = {};
        if (inputValue) {
          data[optionLabelProperty] = {
            value: inputValue,
            operator: "contains",
          };

          onInputChange && onInputChange(inputValue);
        }
        const response = await axiosInstance.post(`${fetchedUrl}`, data, {
          withCredentials: true,
        });
        this.setState({
          defaultData: receivedObject
            ? response.data[receivedObject]
            : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
        // for URLS that use GET HTTP method
      } else if (HTTPMethod.toLocaleLowerCase() === "getjobs") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await axiosInstance.get(
          `${fetchedUrl}?jobName=${content}`,
          {
            withCredentials: true,
          }
        );
        this.setState({
          defaultData: receivedObject
            ? response.data[receivedObject]
            : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      } else if (HTTPMethod.toLocaleLowerCase() === "getcities") {
        let content = "";
        if (inputValue) {
          content = inputValue;
        }
        const response = await axiosInstance.get(
          `${fetchedUrl}?name=${content}`,
          {
            withCredentials: true,
          }
        );
        this.setState({
          defaultData: receivedObject
            ? response.data[receivedObject]
            : response.data,
        });
        const convertedArray = convertDataForSelectBox(
          receivedObject ? response.data[receivedObject] : response.data,
          optionValueProperty,
          optionLabelProperty
        );
        callback(convertedArray);
      }
    } catch (error) {
      this.setState({ is_loaded: false });
      console.log(error);
    }
  };
  
  noOptionsMessage() {
    return this.props.noOptoins;
  }

  render() {
    const {
      value,
      placeholder,
      onChange,
      optionLabelProperty,
      optionValueProperty,
      isDisabled,
      isClearable,
      error
    } = this.props;

    const indicatorSeparatorStyle = {
      alignSelf: 'stretch',
      backgroundColor: "#8080808c",
      marginBottom: 10,
      marginTop: 10,
      width: 0.5,
      marginRight: 5
    };
    
    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={indicatorSeparatorStyle} {...innerProps} />;
    };

    return (
      <AsyncSelect
        defaultValue={value}
        value={value}
        components={{ IndicatorSeparator }}
        noOptionsMessage={this.noOptionsMessage}
        width={"100%"}
        isDisabled={isDisabled}
        escapeClearsValue={true}
        isClearable={isClearable}
        loadOptions={this.getData}
        placeholder={placeholder}
        onChange={onChange}
        menuShouldScrollIntoView={true}
        cacheOptions
        defaultOptions={convertDataForSelectBox(
          this.state.defaultData,
          optionValueProperty,
          optionLabelProperty
        )}
        styles={{
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          loadingIndicator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            display: "none",
          }),
          container: (provided, state) => ({
            ...provided,
            width: "100%",
            height: "100%",
          }),
          placeholder: (provided, state) => ({
            ...provided,
            color: "#808080",
            // fontWeight: "600",
            fontSize: "12px",
            fontFamily: "Open Sans, sans-serif",
          }),
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            height: "100%",
            fontFamily: "Open Sans, sans-serif",
            color: "#808080",
            fontSize: "12px",
            paddingLeft: "2px",
            appearance: "none",
            backgroundColor: "#fff",
            border: error ? "1px solid #F44336" : value ? "1px solid #19B0D2" : "1px solid #8080808c",
            ":hover": {
              border: "1px solid #19B0D2",
            },
            ":focus": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
            },
            ":focus-within": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
              boxShadow: "0 0 0 0 transparent",
            },
            ":active": {
              borderColor: "#19B0D2",
              borderWidth: "1.5px",
            },
          }),
          input: (provided, state) => ({
            ...provided,
            fontFamily: "Open Sans, sans-serif",
            color: "#808080",
            fontSize: "12px",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected && "#19B0D2",
            color: state.isSelected && "#fff",
            fontFamily: "Open Sans, sans-serif",
            fontWeight: "600",
            paddingTop: "10px",
            paddingBottom: "10px",
            ":hover": {
              color: "#fff",
              backgroundColor: "#19B0D2",
            },
          }),
          clearIndicator: (provided, state) => ({
            ...provided,
            paddingRight: 0,
          }),
          dropdownIndicator: (provided, state) => ({
            ...provided,
            paddingLeft: 0,
          }),
        }}
      />
    );
  }
}

export default RecruitmentAsyncSelect;
