import React, { Component } from "react";
import "../../../../css/bootstrap.css";
import "./JobsList.css";
// import { Card, Row, Col } from "react-bootstrap";
import { RiCalendar2Fill } from "react-icons/ri";
import { IoMdArrowRoundForward, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// import StyledContentLoader from "styled-content-loader";
import PublicService from "../../../../services/publicRecruitmentModule/PublicService";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
// import { Button } from "react-bootstrap";
import Loader from "../../../../helpers/publicRecruitmentModule/Loader/Loader";
import { AiOutlineSearch } from "react-icons/ai";
import EllipsisText from "react-ellipsis-text";

class OffersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            elementsPerPage: window.matchMedia("(max-width: 992px)").matches ? 3 : 9,
            data: [],
            totalItems: 0,
            is_loaded: true,
            jobName: "",
        };
    }

    compo;

    componentDidMount() {
        let state = this.props.location.state;
        const isMobile = window.matchMedia("(max-width: 992px)").matches;
        if (isMobile) {
            this.setState({ elementsPerPage: 3 });
        }

        if (state && state.jobName) {
            this.setState({ jobName: state.jobName }, () => {
                this.getAllJobs();
            });
        } else {
            this.getAllJobs();
        }
    }

    getAllJobs = async () => {
        const { jobName, currentPage, elementsPerPage } = this.state;
        try {
            let response = await PublicService.getAllJobs(jobName, currentPage, elementsPerPage);
            this.setState({
                is_loaded: false,
                data: response.data.jobs,
                totalItems: response.data.jobs?.length,
            });
        } catch (error) {
            console.warn(error);
        }
    };

    forWard = () => {
        const ele = document.getElementById("cardhead");
        ele.scrollIntoView();

        this.setState(
            {
                currentPage: this.state.currentPage + 1,
                is_loaded: false,
            },
            () => {
                this.getAllJobs();
            }
        );
    };
    backWard = () => {
        const ele = document.getElementById("cardhead");
        ele.scrollIntoView();

        this.setState(
            {
                currentPage: this.state.currentPage - 1,
                is_loaded: false,
            },
            () => {
                this.getAllJobs();
            }
        );
    };
    newTag = (postDay) => {
        let currentDate = moment(moment().format("YYYY/MM/DD"));
        let diff = currentDate.diff(postDay, "days");
        if (diff <= 7) {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { is_loaded, data, currentPage, elementsPerPage, totalItems, jobName } = this.state;
        console.log(totalItems);
        console.log(elementsPerPage);
        return (
            //   <StyledContentLoader
            //     backgroundColor="#F7F7F7"
            //     foregroundColor="#DEDEDE"
            //     // isLoading={is_loaded}
            //   >
            <>
                {is_loaded && <Loader />}
                <div className="public_recruitment" id="cardhead">
                    <div className="titleSearch">
                        <div className="title">
                            <h2>
                                Offres <span className="fw-bold">actuelles</span>
                            </h2>
                        </div>
                        <div className="search">
                            <input
                                type="search"
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        this.getAllJobs();
                                    }
                                }}
                                placeholder="Chercher une offre ici…"
                                value={jobName}
                                onChange={(e) => {
                                    this.setState({
                                        jobName: e.target.value,
                                    });
                                }}
                            />
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    this.getAllJobs();
                                }}
                            >
                                <AiOutlineSearch
                                    className="searchBtnIcon"
                                    style={{ color: "#9D9D9D", height: "30px", width: "30px" }}
                                />
                            </button>
                        </div>
                    </div>
                    <div className="allCards">
                        <div className="row justify-content-center" style={{ width: "100%" }}>
                            {data &&
                                data.map((job) => {
                                    return (
                                        <div className="col-xl-4 col-lg-6 mb-5">
                                            <div
                                                className="card"
                                                style={{
                                                    width: "90%",
                                                    minHeight: "200px",
                                                    height: "100%",
                                                }}
                                                key={job.id}
                                            >
                                                {this.newTag(job.updatedAt) && <div className="newTag">New</div>}
                                                <div
                                                    className="card-body"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "space-between",
                                                        height: "100%",
                                                    }}
                                                >
                                                    <div>
                                                        <div className="card-title h5">{job.jobName}</div>
                                                        <div className="card-text">
                                                            {job.shortDescription && (
                                                                <>
                                                                    <EllipsisText
                                                                        text={job.shortDescription}
                                                                        length={"120"}
                                                                        tooltip={true}
                                                                    />
                                                                    <span>
                                                                        {job.shortDescription.length > 120 ? "..." : ""}
                                                                    </span>{" "}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="CardFooter row">
                                                        <div
                                                            className="col"
                                                            style={{
                                                                textAlign: "left",
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <div className="calendarCountainer">
                                                                <RiCalendar2Fill style={{ color: "#19B0D2" }} />
                                                            </div>
                                                            <p className="dateText">
                                                                <span style={{ fontSize: "8px" }}>Publiée le: </span>
                                                                <span style={{ fontWeight: "600" }}>
                                                                    {moment(job.updatedAt).format("YYYY/MM/DD")}
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <div
                                                            className="col"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <Link
                                                                to={"/jobs/" + job.id + "/details"}
                                                                className="readMoreLink"
                                                            >
                                                                <button
                                                                    className="CardBtn"
                                                                    // onClick={() => {
                                                                    //   this.props.history.push(`/admin/trainers/view/${job.id}`);
                                                                    // }}
                                                                >
                                                                    <div className="row">
                                                                        <div className="BtnTxt col">Lire plus</div>
                                                                        <div className="col">
                                                                            <IoMdArrowRoundForward
                                                                                style={{
                                                                                    height: "15px",
                                                                                    width: " 15px",
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="paginationCountainer">
                        <div>
                            {currentPage > 1 && (
                                <button className="tableBtnBack" id="tableBtnBack" onClick={this.backWard}>
                                    <IoIosArrowBack values={{ color: "red", size: "50px" }} />
                                </button>
                            )}

                            <div className="tableNbr">{currentPage}</div>

                            {totalItems === elementsPerPage && (
                                <button className="tableBtnForward" id="tableBtnForward" onClick={this.forWard}>
                                    <IoIosArrowForward />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </>
            //   </StyledContentLoader>
        );
    }
}

export default withRouter(OffersList);
